export default {
  // Endpoints
  baseURL: 'https://app.renovaremcristo.org.br/renovar-service/api',
  loginEndpoint: '/auth/',
  registerEndpoint: '/jwt/register',
  refreshEndpoint: '/jwt/refresh-token',
  logoutEndpoint: '/jwt/logout',
/*
  baseURL: 'https://127.0.0.1:8000/api',
  loginEndpoint: '/auth',
  registerEndpoint: '/jwt/register',
  refreshEndpoint: '/refresh-token',
  logoutEndpoint: '/logout',
*/

  // This will be prefixed in authorization header with token
  // e.g. Authorization: Bearer <token>
  tokenType: 'Bearer',

  // Value of this property will be used as key to store JWT token in storage
  storageTokenKeyName: 'accessToken',
  storageRefreshTokenKeyName: 'refreshToken',
}
