export default [
  {
    path: '/dashboard',
    name: 'dashboard-renovar',
    component: () => import('@/views/dashboard/renovar/Index.vue'),
  },
  {
    path: '/dashboard/ecommerce',
    name: 'dashboard-ecommerce',
    component: () => import('@/views/dashboard/ecommerce/Ecommerce.vue'),
  },
  {
    path: '/supervisores',
    name: 'supervisores-index',
    component: () => import('@/views/supervisors/Index.vue'),
  },
  {
    path: '/pessoas-sem-pg',
    name: 'pessoas-sem-pg-index',
    component: () => import('@/views/pessoas-sem-pg/Index.vue'),
  },
  {
    path: '/visitantes',
    name: 'visitantes-index',
    component: () => import('@/views/visitors/Index.vue'),
  },
  {
    path: '/pgs',
    name: 'pg-index',
    component: () => import('@/views/pgs/Index.vue'),
  },
  {
    path: '/pgs/:id',
    name: 'pg-detalhes',
    component: () => import('@/views/pgs/Detalhes.vue'),
  },
  {
    path: '/lessons',
    name: 'lessons-index',
    component: () => import('@/views/lessons/Index.vue'),
  },
]
