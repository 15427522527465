import Vue from 'vue'

// axios
import axios from 'axios'

const axiosIns = axios.create({
  // You can add your headers here
  // ================================
  // baseURL: 'https://some-domain.com/api/',
  baseURL: 'https://app.renovaremcristo.org.br/renovar-service/api',
  // timeout: 1000,
  // headers: {'X-Custom-Header': 'foobar'}
})
const axiosInsReport = axios.create({
  // You can add your headers here
  // ================================
  // baseURL: 'https://some-domain.com/api/',
  baseURL: 'https://reports.renovaremcristo.org.br/renovar-reports/api',
  // timeout: 1000,
  // headers: {'X-Custom-Header': 'foobar'}
})
const axiosInsFiles = axios.create({
  // You can add your headers here
  // ================================
  // baseURL: 'https://some-domain.com/api/',
  baseURL: 'https://apifiles.renovaremcristo.org.br/renovar-files/api/files/v1/',
  // timeout: 1000,
  // headers: {'X-Custom-Header': 'foobar'}
})

Vue.prototype.$http = axiosIns
Vue.prototype.$httpReport = axiosInsReport
Vue.prototype.$httpFiles = axiosInsFiles

export default axiosIns
